import type { ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import { reverse } from "remeda";
import { Tile, TileSelect } from "../../../components/forms/TileSelect.tsx";
import { ServiceImageIcon } from "../ServiceImageIcon.tsx";
import type { ServiceOs } from "./serviceOs.ts";

export interface OsVersionRadioProps {
    items: ServiceImage[];

    value?: ServiceOs;
    onChange?: (os: ServiceOs) => void;
}

export function OsVersionSelectCustom({ value, onChange, items }: OsVersionRadioProps) {
    const tiles = items.map((item) => {
        const { service_name, display_name, versions } = item;

        const sortedVersions = reverse(versions);

        return (
            <Tile<ServiceOs>
                key={service_name}
                icon={(isSelected) => <ServiceImageIcon image={item} isSelected={isSelected} className="size-2rem" />}
                title={display_name}
                optionHeader="Version"
                items={sortedVersions.map((version) => ({
                    key: version,
                    value: { os_name: service_name, os_version: version },
                    label: version,
                }))}
            />
        );
    });

    return (
        <TileSelect
            value={value}
            equal={(a, b) => a.os_name === b.os_name && a.os_version === b.os_version}
            onChange={onChange}
        >
            {tiles}
        </TileSelect>
    );
}
