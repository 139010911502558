import FF from "../../components/forms/FormField.module.css";

import { notNull } from "@warrenio/utils/notNull";
import type { ComponentProps, ReactNode } from "react";
import { useState } from "react";
import { useFocus } from "react-aria";
import { MonthlyCostElement } from "../../components/forms/MonthlyCostField.tsx";
import { mcn } from "../../utils/baseProps.ts";
import { cn } from "../../utils/classNames.ts";
import type { Price } from "../pricing/resourcePricing.ts";

export interface SizeBannerProps extends ComponentProps<"div"> {
    /** Either this or {@link price} must be set. */
    priceComponent?: ReactNode;
    price?: Price;

    children: ReactNode;
    notice?: ReactNode;

    isSelected?: boolean;
    isDisabled?: boolean;
    variant?: "custom";
}

const variantClasses = {
    custom: FF.Custom,
};

export function SizeBanner({
    price,
    priceComponent = <MonthlyCostElement price={notNull(price, "price and priceComponent")} compact />,
    children,
    notice,
    isSelected,
    isDisabled,
    variant,
    ...props
}: SizeBannerProps) {
    const [isFocus, setFocus] = useState(false);
    const { focusProps } = useFocus({
        onFocusChange: (isFocused) => setFocus(isFocused),
    });

    return (
        <div
            {...focusProps}
            tabIndex={isDisabled ? undefined : 0}
            data-selected={isSelected ? true : undefined}
            data-disabled={isDisabled ? true : undefined}
            data-focused={isFocus ? true : undefined}
            {...mcn(cn(FF.FormFieldIconRadio, FF.Size, variant && variantClasses[variant]), props)}
        >
            <div className={FF.Price}>{priceComponent}</div>
            <div className={FF.Content}>{children}</div>

            {!!notice && <div className={FF.Banner}>{notice}</div>}
        </div>
    );
}
