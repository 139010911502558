import type { ManagedServiceCreateBody, ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import type { GetField, SetField } from "../../utils/getField.tsx";
import type { VmSizePackage } from "../compute/VmSize.types.ts";
import {
    forceSizeForOs,
    getDefaultSizeForOs,
    getDefaultSizeRange,
    onSizeChange,
    type SizeInputs,
    type SizeViewModel,
} from "../compute/commonViewModel.ts";
import type { SelectedOs } from "../compute/os/os.ts";
import type { SizeValue, VmSizeParams } from "../compute/vmSizeSelectUtils.ts";
import type { LocationInputs } from "../location/LocationField.tsx";
import type { LocationsForType } from "../location/location.ts";
import type { LocationSlug } from "../location/query.ts";
import { DefaultNodeCount } from "./FailoverNodeSelect.tsx";
import type { ServiceOs } from "./os/serviceOs.ts";

export interface ServiceCreateInputs
    extends Pick<ManagedServiceCreateBody, "display_name" | "billing_account_id">,
        LocationInputs,
        SizeInputs {
    service: ServiceOs;
    network_uuid?: string;
    reserve_public_ip: boolean;
    failover_nodes: number;
}

export class ServiceCreateViewModel implements SizeViewModel {
    get: GetField<ServiceCreateInputs> = (_n) => undefined;
    set: SetField<ServiceCreateInputs> = (_n, _v) => {};

    constructor(
        private readonly availableImages: ServiceImage[],
        private readonly locations: LocationsForType,
        public readonly _allSizePackages: VmSizePackage[],
        public readonly _sizeParams: VmSizeParams,
    ) {}

    attachGetterAndSetter(getter: typeof this.get, setter: typeof this.set) {
        this.get = getter;
        this.set = setter;
    }

    /** Delegate for {@link SizeViewModel} */
    get _os(): SelectedOs {
        return this.get("service") ?? this.getDefaultService();
    }

    onChangeService = (newValue: ServiceOs) => {
        console.log("onChangeService, value:", newValue);
        this.set("service", newValue);

        forceSizeForOs.call(this, newValue);

        this.set("failover_nodes", this.getDefaultNodeCountForService(newValue));
    };

    onLocationChange = (newValue: string) => {
        console.log("onLocationChange, value:", newValue);
        this.set("location", newValue);
        this.set("network_uuid", undefined); // field selects it's own default and sets it to form field
    };

    onSizeChange = (newValue: SizeValue, forceRecalculate = false) => {
        onSizeChange.call(this, newValue, forceRecalculate);
    };

    private getDefaultSize(): SizeValue {
        return getDefaultSizeForOs.call(this, this.getDefaultService());
    }

    private getDefaultLocation(): LocationSlug {
        return this.locations.defaultLocation;
    }

    private getDefaultService(): ServiceOs {
        return {
            os_name: this.availableImages[0].service_name,
            os_version: this.availableImages[0].versions[0],
        };
    }

    private getDefaultNodeCountForService(service: ServiceOs): number {
        if (service.os_name === "mariadb") return 0;
        return DefaultNodeCount;
    }

    getDefaultValues(service?: Partial<ServiceCreateInputs>): Omit<ServiceCreateInputs, "billing_account_id"> {
        console.log("Getting form default values");
        return {
            display_name: "",
            reserve_public_ip: true,
            ...service,

            location: service?.location ?? this.getDefaultLocation(),
            failover_nodes: service?.failover_nodes ?? this.getDefaultNodeCountForService(this.getDefaultService()),
            service: service?.service ?? this.getDefaultService(),
            size: service?.size ?? this.getDefaultSize(),
            size_ranges: getDefaultSizeRange.call(this, service?.service, service?.size?.disks),
        };
    }
}
