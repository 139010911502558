import { first } from "remeda";
import type { HostPoolWithType } from "../../pools/query.ts";

export function getPoolsForLocation<TPool extends HostPoolWithType>(pools: TPool[], locationSlug: string): TPool[] {
    return pools.filter((p) => p.location === locationSlug);
}

export function getDefaultDesignatedPoolForLocation(pools: HostPoolWithType[], locationSlug: string) {
    return getPoolsForLocation(pools, locationSlug).find((p) => p.is_default_designated) ?? first(pools);
}
