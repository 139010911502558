import { createLazyFileRoute, useRouterState } from "@tanstack/react-router";
import type { ServiceOs } from "../../../modules/services/os/serviceOs.ts";
import { ServiceCreate } from "../../../modules/services/ServiceCreate.tsx";
import type { ServiceCreateInputs } from "../../../modules/services/serviceCreateViewModel.ts";

export interface AppSearch {
    osName?: string;
    osVersion?: string;
}

export const Route = createLazyFileRoute("/_main/services/create")({
    component: Component,
});

function Component() {
    const { osName, osVersion } = Route.useSearch();

    // TODO: make an utility function to handle this form-loading logic

    // Load previous form state from the router (in case we are retrying from an error notification)
    const mutationState = useRouterState({ select: (s) => s.location.state?.serviceCreateMutation });
    const inputState = useRouterState({ select: (s) => s.location.state?.serviceCreateInputs });

    const formStateFromUrl: Partial<ServiceCreateInputs> | undefined =
        osName && osVersion ? { service: { os_name: osName, os_version: osVersion } as ServiceOs } : undefined;

    console.debug("ServiceCreate router state: %o", mutationState);

    const uuid = mutationState?.requestUuid;
    return (
        <ServiceCreate
            // Reset state when eg. clicking on "Retry" and already in the "New" page
            key={uuid}
            previousRequestUuid={uuid}
            inputs={inputState ?? mutationState?.inputs ?? formStateFromUrl}
        />
    );
}
