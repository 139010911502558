import { createLazyFileRoute, useRouterState } from "@tanstack/react-router";
import type { ComputeImageType } from "../../../modules/compute/os/os.ts";
import { VmCreate } from "../../../modules/compute/VmCreate.tsx";
import type { VmCreateInputs } from "../../../modules/compute/vmCreateViewModel.tsx";

export interface AppSearch {
    typeId?: ComputeImageType;
    osName?: string;
    osVersion?: string;
}

export const Route = createLazyFileRoute("/_main/compute/create")({
    component: Component,
});

function Component() {
    const search: AppSearch = Route.useSearch();

    // TODO: make an utility function to handle this form-loading logic

    // Load previous form state from the router (in case we are retrying from an error notification)
    const mutationState = useRouterState({ select: (s) => s.location.state?.vmCreateMutation });
    const inputState = useRouterState({ select: (s) => s.location.state?.vmCreateInputs });
    const formStateFromUrl: Partial<VmCreateInputs> =
        search.typeId && search.osName && search.osVersion
            ? { typeId: search.typeId, os: { os_name: search.osName, os_version: search.osVersion } }
            : {};
    console.debug("VmCreate router state: %o", mutationState);

    const uuid = mutationState?.requestUuid;
    return (
        <VmCreate
            // Reset state when eg. clicking on "Retry" and already in the "New" page
            key={uuid}
            previousRequestUuid={uuid}
            inputs={inputState ?? mutationState?.inputs ?? formStateFromUrl}
        />
    );
}
