import { useConfig } from "../../config.ts";
import type { VmSizePackage } from "./VmSize.types.ts";

const defaultPackages: VmSizePackage[] = [
    { cpu: 1, ram: 0.5, ssd: 20, default: [], os: ["other"] },
    { cpu: 1, ram: 1, ssd: 30, default: ["other"], os: ["other"] },
    { cpu: 2, ram: 2, ssd: 40, default: [], os: ["other", "win"] },
    { cpu: 2, ram: 4, ssd: 60, default: ["win"], os: ["other", "win"] },
    { cpu: 4, ram: 8, ssd: 120, default: [], os: ["win"] },
    { cpu: 8, ram: 16, ssd: 240, default: [], os: ["win"] },
];

export function usePackages() {
    const { predefinedPackages } = useConfig();
    const packages = predefinedPackages?.length ? predefinedPackages : defaultPackages;
    return packages.map((p): VmSizePackage => ({ ...p, ram: p.ram * 1024 }));
}
