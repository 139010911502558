import type { VmCreateBody, VmImage } from "@warrenio/api-spec/spec.oats.gen";
import type { ReactNode } from "react";
import type { IconRadioContentProps } from "../../components/forms/IconRadio.tsx";
import type { GetField, SetField } from "../../utils/getField.tsx";
import type { LocationInputs } from "../location/LocationField.tsx";
import type { LocationsForType } from "../location/location.ts";
import type { LocationSlug } from "../location/query.ts";
import type * as hostPoolsQuery from "../pools/query.ts";
import type { VmSizePackage } from "./VmSize.types.ts";
import {
    forceSizeForOs,
    getDefaultSizeForOs,
    getDefaultSizeRange,
    onSizeChange,
    type SizeInputs,
    type SizeViewModel,
} from "./commonViewModel.ts";
import { type ComputeImageType, getDefaultOs, getResourceTypeImages, type SelectedOs } from "./os/os.ts";
import { getDefaultDesignatedPoolForLocation } from "./pool/pool.ts";
import type { SizeValue, VmSizeParams } from "./vmSizeSelectUtils.ts";

export interface ItemType extends IconRadioContentProps {
    id: ComputeImageType;
    description: ReactNode;
}

export interface VmCreateInputs
    extends Pick<VmCreateBody, "network_uuid" | "source_uuid" | "source_replica" | "billing_account_id">,
        Required<
            Pick<
                VmCreateBody,
                "name" | "username" | "password" | "public_key" | "designated_pool_uuid" | "reserve_public_ip"
            >
        >,
        LocationInputs,
        SizeInputs {
    typeId: ComputeImageType;
    // NB: Bundle the OS form control state into an object so it can be integrated nicely with react-hook-form's <Controller>
    os: SelectedOs;
    password_confirm: string;
}

export class VmCreateViewModel implements SizeViewModel {
    get: GetField<VmCreateInputs> = (_n) => undefined;
    set: SetField<VmCreateInputs> = (_n, _v) => {};

    constructor(
        // Necessary source data & configuration to validate values
        private readonly types: ItemType[],
        private readonly allImages: VmImage[],
        private readonly locations: LocationsForType,
        private readonly pools: hostPoolsQuery.HostPoolWithType[],
        public readonly _allSizePackages: VmSizePackage[],
        public readonly _sizeParams: VmSizeParams,
    ) {}

    attachGetterAndSetter(getter: typeof this.get, setter: typeof this.set) {
        this.get = getter;
        this.set = setter;
    }

    onChangeType = (newValue: ComputeImageType) => {
        console.debug("onChangeType, value:", newValue);
        this.onChangeOs(this.getDefaultOsForType(newValue));
        this.set("typeId", newValue);
    };

    get _os(): SelectedOs {
        return this.get("os") ?? this.getDefaultOsForType(this.getDefaultType());
    }

    onChangeOs = (newValue: SelectedOs) => {
        console.debug("onChangeOs, value:", newValue);
        this.set("os", newValue);

        forceSizeForOs.call(this, newValue);
    };

    onLocationChange = (newValue: string) => {
        console.debug("onLocationChange, value:", newValue);
        this.set("location", newValue);
        this.onDesignatedPoolChange(getDefaultDesignatedPoolForLocation(this.pools, newValue)?.uuid ?? "");
        this.set("network_uuid", undefined); // field selects it's own default and sets it to form field
    };

    onDesignatedPoolChange = (newValue: string) => {
        console.debug("onDesignatedPoolChange, value:", newValue);
        this.set("designated_pool_uuid", newValue);
    };

    onSizeChange = (newValue: SizeValue, forceRecalculate = false) => {
        onSizeChange.call(this, newValue, forceRecalculate);
    };

    //#region Default values
    private getDefaultSize(): SizeValue {
        return getDefaultSizeForOs.call(this, this.getDefaultOsForType(this.getDefaultType()));
    }

    private getDefaultType(): ComputeImageType {
        return this.types[0].id;
    }

    private getDefaultLocation(): LocationSlug {
        return this.locations.defaultLocation;
    }

    private getDefaultOsForType(type: ComputeImageType): SelectedOs {
        const imagesForType = getResourceTypeImages(this.allImages, type);
        return getDefaultOs(imagesForType);
    }

    private getDefaultOs(): SelectedOs {
        return this.getDefaultOsForType(this.getDefaultType());
    }

    private getDefaultDesignatedPoolUuid() {
        return getDefaultDesignatedPoolForLocation(this.pools, this.getDefaultLocation())?.uuid;
    }

    getDefaultValues(vm?: Partial<VmCreateInputs>): VmCreateInputs {
        console.debug("Getting form default values");
        return {
            name: "",
            public_key: "",
            username: "",
            password: "",
            password_confirm: "",
            ...vm,

            typeId: vm?.typeId ?? this.getDefaultType(),
            location: vm?.location ?? this.getDefaultLocation(),
            reserve_public_ip: vm?.reserve_public_ip ?? true,
            os: vm?.os ?? this.getDefaultOs(),
            designated_pool_uuid: vm?.designated_pool_uuid ?? this.getDefaultDesignatedPoolUuid() ?? "",
            size: vm?.size ?? this.getDefaultSize(),
            size_ranges: getDefaultSizeRange.call(this, vm?.os, vm?.size?.disks),
        };
    }
    //#endregion Default values
}
